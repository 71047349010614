import React, { useContext, useEffect, useRef, useState } from 'react'
import PinkTick from '../../Icons/PinkTick'
import FacebookIcon from '../../Icons/FacebookIcon'
import InstagramIcon from '../../Icons/InstagramIcon'
import YoutubeIcon from '../../Icons/YoutubeIcon'
import TwitterIcon from '../../Icons/TwitterIcon'
import BannerCourseCard from '../Card/BannerCourseCard'
import defaultImage from '../../Assest/Hero.webp';
import { SlaData } from '../../Context/SLAstore'
import { useNavigate } from 'react-router-dom'
import StudentContact from '../StudentContact'
import NavBar from '../NavBar'
import CourseEnquiryForm from '../CourseComponent/CourseEnquiryForm'
import SearchIcon from '../../Icons/SearchIcon'
const CourseBanner2 = ({ page, grd, theme, image, txtb, txta, spl, cssa, cssb, splcss, content, content_css }) => {
    let backgroundimg = {}
    let navigate = useNavigate()
    let formref = useRef()
    let [showForm, setShowForm] = useState()

    let { allCourses } = useContext(SlaData)
    let [searchWord, setSearchWord] = useState('')
    let [studentShow, setStudentShow] = useState(false)

    let [filterList, setfilterList] = useState()
    let search = () => {
        let filteredArry = [...allCourses].filter((obj) => obj.Title.toLowerCase().indexOf(searchWord.toLowerCase()) != -1)
        setfilterList(filteredArry)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formref.current && !formref.current.contains(event.target))
                setShowForm(false)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.addEventListener('mousedown', handleClickOutside)
        }
    }, [])
    return (
        <div className={` relative overflow-hidden `} >
            <div className=' bannerclr1 absolute -z-0 w-[1300px] h-[1300px] rounded-full ' >
            </div>
            <button onClick={() => setShowForm((prev) => !prev)}
                className='btnbg2 
                       absolute left-0 z-10 top-1/2 d-lg-none
                        rounded-r -translate-y-1/2  
                        text-white p-2 text-xs vrl ' >
                Quick enquiry
            </button>
            {/* <div className=' bannerclr2 absolute blur-3xl -z-0 w-[1200px] h-[1200px] rounded-full ' >
            </div> */}
            <img src={require('../../Assest/dotbg.png')} className=' absolute top-0 -z-0 ' alt="DotBad" />
            <NavBar theme={theme} />
            <main onClick={() => setfilterList(false)} className={`min-h-[60vh] xl:min-h-[90vh] py-5 relative flex p-4 `} >

                <section
                    className={` border-4 shadow shadow-slate-100 border-slate-100 border-opacity-40 m-auto 
                        row w-[98%] min-h-[70vh] xl:min-h-[70vh] relative
                  p-3 rounded-3xl bg-white bg-opacity-10 backdrop-blur-[1px] `} >
                    <div className='absolute d-none d-lg-block flex py-3 top-[100%] ' >
                        <button onClick={() => setShowForm((prev) => !prev)}
                            className={` btnbg2 z-10 w-fit mx-auto
                        rounded fw-medium px-4 text-white p-2 text-sm poppins  `} >
                            Quick Enquiry
                        </button>

                    </div>
                    {/* <div ref={formref} className={`${showForm ? 'opacity-100 ' : 'hidden  '} 
                        duration-400 w-[80%] max-w-[400px] absolute  z-20 
                       top-14 left-1/2 -translate-x-1/2 
                         lg:translate-x- lg:left-3/4 `} >
                    <CourseEnquiryForm />
                </div> */}
                    <article className={` flex flex-col m-auto text-center
                         ${page != 'course' && "justify-between"} `}>
                        <>


                            <h4 className={` ${cssb ? cssb : "audiowide  text-5xl text-white"} 
                            text-5xl text-center xl:text-5xl mx-auto
                              md:w-4/5 lg:w-4/6 xl:w-[72%] h-fit  `} >
                                <span className={` ${cssb} `} >{txtb ? txtb : " "}  </span>
                                <span className={` ${splcss ? splcss : "txtgrd"} `}> {spl ? spl : ""} </span>
                                <span className={` ${cssa} `} > {txta ? txta : ""}  </span>
                            </h4>
                            <p className={`text-sm ${content_css ? content_css : "sulphur text-slate-300"} md:w-4/5 lg:w-3/5 text-slate-700 mx-auto text-sm my-4  `} >
                                {content ? content : ` Build stunning, high-performance apps for iOS, Android, and Web with Flutter. Achieve faster development,
                                    seamless scalability, and exceptional user experiences—all from a single codebase.`} </p>

                            <div className='my-4 flex justify-center  mx-auto  lg:w-[60%] ' >
                                <div className='relative  w-2/3 sm:w-1/2 ' >
                                    <input onKeyUp={(e) => {
                                        if (e.key == 'Enter')
                                            search()
                                    }} type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)}
                                        placeholder='Search your course... '
                                        className='p-2 border-2  rounded outline-none
                                     text-white border-slate-200 backdrop-blur-sm bg-opacity-15 bg-slate-200  w-full ' />
                                    {filterList &&
                                        <section className='rounded z-10 overflow-y-scroll searchscroll h-32 absolute 
                                text-slate-600  border-2 w-full border-slate-200 backdrop-blur-sm bg-opacity-15 bg-slate-200  top-14 ' >
                                            {filterList && filterList.length > 0 ? filterList.map((obj, index) => (
                                                <button
                                                    onClick={() => {
                                                        setSearchWord('');
                                                        navigate(`/${obj.slug}`)
                                                    }}
                                                    className='p-1 px-2 text-xs w-full text-start hover:bg-slate-200 ' > {obj.Title} </button>
                                            )) : <p className='p-2 text-sm '>No Result found </p>
                                            }
                                        </section>}
                                </div>
                                <button onClick={() => search()} className='btnbg2 text-xs sm:text-base p-2 px-3 bg-opacity-90 rounded mx-2 text-white' >
                                    <SearchIcon />
                                </button>
                            </div>
                        </>

                    </article>
                    <article className='flex justify-between almaz gap-3 items-center rounded ' >
                        <ul  className={`${theme == 'dark' ? 'text-slate-800' : 'text-slate-100'} list-disc  almaz tracking-wider `} >
                            <li> <BannerCourseCard heading="Work Integration" content='Engage with industry projects and gain practical skills. 
                                    Our industry experts guide you to become job-ready from day one.' />
                            </li>
                            <li> <BannerCourseCard heading='Project-Based Learning' content='Sharpen your skills through hands-on tasks. This 
                                    practical approach sets you up for success in your chosen field.' />
                            </li>
                        </ul>

                        <div>
                            <div className={` flex gap-2 items-start justify-end ${theme == 'dark' ? 'text-slate-800' : 'text-slate-100'}`} >

                                <BannerCourseCard rtl heading='Networking Opportunities' content='Connect with like-minded peers and industry 
                                    professionals. Find job opportunities through our active and supportive community.' />
                                <div className='w-[4px] h-[4px] mt-2 rounded-full bg-slate-700 ' >
                                </div>
                            </div>
                            <div className='flex gap-2 items-start justify-end ' >
                                <BannerCourseCard rtl heading=' Opportunities' content='Connect with like-minded peers and industry 
                                    professionals. Find job opportunities through our active and supportive community.' />
                                <div className='w-[4px] h-[4px] mt-2 rounded-full bg-slate-700 ' >
                                </div>
                            </div>
                        </div>


                    </article>
                    <div ref={formref} className={`${showForm ? 'opacity-100 ' : ' hidden '} 
                        duration-400 w-[80%] col-md-8 col-lg-4 left-1/2 -translate-x-1/2 z-50 absolute top-2 `} >
                        <CourseEnquiryForm css=' bg-black bg-opacity-50 backdrop-sm ' />
                    </div>

                </section>
            </main>
            <StudentContact show={studentShow} setshow={setStudentShow} />
        </div>
    )
}


export default CourseBanner2