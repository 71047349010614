import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const BannerCourseCard = ({ heading, content ,rtl}) => {
    let [selectedHeading, setSelectedHeading] = useState()
    const renderTooltip = (text) => (
        <Tooltip className=' bg-slate-white flex text-black ' id="button-tooltip">
            {text}
        </Tooltip>
    );
    return (
        <div
            onMouseLeave={() => setSelectedHeading('')}
            className=' w-fit relative ' >
            <p onMouseEnter={() => setSelectedHeading(heading)}
                className={` ${rtl?' ':''} mb-1 cursor-pointer hover:text-violet-600 duration-500 hover:fw-semibold `}>
                {heading} </p>
            {/* Tooltip */}
            <div className={` ${selectedHeading == heading ? 'opacity-100 z-[100] ' : 'opacity-0 '} 
            duration-700 absolute top-0 ${rtl?' right-[102%] ': "left-[100%]"} `} >
                <div className=' row w-[20rem] rounded overflow-hidden p-0 mx-0' >
                    {/* <div style={{ clipPath: "polygon(100% 0, 0 0, 100% 100%)" }}
                        className='tooltip bg-white  ' >

                    </div> */}
                    {/* Content */}
                    <div className={`bg-white  ${rtl?' order-2 ':''} text-slate-800  text-xs px-0 mx-0 col-8 poppins p-2 `} >
                        <p className='p-2 ' >
                            {content}
                        </p>
                    </div>
                    {/* Image */}
                    <img src={require('../../Assest/bannerSmallImage.png')} alt="BannerImage"
                        className={` ${rtl?' order-1 ':''} col-4 px-0 object-cover  `} />
                </div>
            </div>
        </div >

    )
}

export default BannerCourseCard