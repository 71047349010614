import React, { useContext, useEffect, useRef, useState } from 'react'
import NavBar from '../NavBar'
import HomeBanner from '../HomeComponent/HomeBanner'
import { useNavigate } from 'react-router-dom'
import { SlaData } from '../../Context/SLAstore'
import SearchIcon from '../../Icons/SearchIcon'
import PinkTick from '../../Icons/PinkTick'
import BannerCourseCard from '../Card/BannerCourseCard'
import CourseEnquiryForm from '../CourseComponent/CourseEnquiryForm'

const HomeBannerNew = () => {
    let navigate = useNavigate()
    let formref = useRef()
    let [showForm, setShowForm] = useState()
    let { allCourses } = useContext(SlaData)
    let [searchWord, setSearchWord] = useState('')
    let [studentShow, setStudentShow] = useState(false)

    let [filterList, setfilterList] = useState()
    let search = () => {
        let filteredArry = [...allCourses].filter((obj) => obj.Title.toLowerCase().indexOf(searchWord.toLowerCase()) != -1)
        setfilterList(filteredArry)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formref.current && !formref.current.contains(event.target))
                setShowForm(false)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.addEventListener('mousedown', handleClickOutside)
        }
    }, [])
    return (
        <main className='bg-black pb-4 relative '  >
            <button onClick={() => setShowForm((prev) => !prev)}
                className='btnbg2 
                       absolute left-0 z-10 top-1/2 d-lg-none
                        rounded-r -translate-y-1/2  
                        text-white p-2 text-xs vrl ' >
                Quick enquiry
            </button>
            <NavBar css={'bg-black'} />
            <div className='min-h-[90vh] flex relative ' >
                <main className=' container max-w-[95%] items-end row relative my-auto mx-auto min-h-[40vh] lg:min-h-[80vh] gradient-box' >
                    <section className='col-lg-6 p-3  min-h-[40vh] lg:min-h-[80vh] '>
                        <button onClick={() => setShowForm((prev) => !prev)}
                            className='btnbg2 
                       absolute top-0 z-10 d-none d-lg-block
                        rounded-b fw-medium right-1/4 px-4
                        text-white p-2 text-sm poppins rounded-t-none  ' >
                            Quick Enquiry
                        </button>
                        <div ref={formref} className={`${showForm ? 'opacity-100 ' : 'hidden  '} 
                        duration-400 w-[80%] max-w-[400px] absolute  z-20 
                       top-14 left-1/2 -translate-x-1/2 
                         lg:translate-x- lg:left-3/4 `} >
                            <CourseEnquiryForm />
                        </div>
                        <article className=' ' >
                            <h4 className='cantry my-3 text-slate-100 text-4xl sm:text-5xl lg:text-[2.7rem] xl:text-6xl  ' >
                                People who care about your <span className=' text-[rgb(141,0,246)] ' > growth </span> </h4>
                            <p className=' urbanist my-3 text-slate-100 ' >
                                Powerful, self-serve product and growth analytics to help you convert, engage, and retain more.
                            </p>
                            <div className='my-4 flex relative ' >
                                <input onKeyUp={(e) => {
                                    if (e.key == 'Enter')
                                        search()
                                }} type="text" value={searchWord} onChange={(e) => setSearchWord(e.target.value)}
                                    placeholder='Search your course... '
                                    className='p-2 border-2  w-2/3 sm:w-1/2  rounded outline-none
                                     text-white border-slate-600
                                     bg-slate-800 bg-opacity-75 ' />
                                <button onClick={() => search()} className={` btnbg2 text-xs sm:text-base px-3 p-2 bg-opacity-90 rounded mx-2 text-white`} >
                                    <SearchIcon />
                                </button>
                                {filterList &&
                                    <section className='rounded z-10 w-2/3 sm:w-1/2 overflow-y-scroll searchscroll h-32 absolute 
                                  text-white border-slate-700 border-2
                                     bg-slate-800 top-14 ' >
                                        {filterList.length > 0 ? filterList.map((obj, index) => (
                                            <button
                                                onClick={() => {
                                                    setSearchWord('');
                                                    navigate(`/${obj.slug}`)
                                                }}
                                                className='p-1 px-2 text-xs w-full text-start hover:bg-slate-700 ' > {obj.Title} </button>
                                        )) : <p className='p-2 text-sm '>No Result found </p>
                                        }
                                    </section>}

                            </div>
                            <p className='md:w-4/5 urbanist text-slate-300 poppins text-sm my-2 ' >
                                We care about your data in our <a href='/terms-and-condition' className=' text-[#8E01F7] cursor-pointer ' > privacy policy. </a>
                                {/* <a className='text-slate-300  ' href=""> privacy policy. </a> */}
                            </p>
                            <section className='flex gap-2 urbanist text-sm my-3' >
                                <div className='flex gap-2 text-white items-center  ' >
                                    <span className='text-pink-200 ' >
                                        <PinkTick />
                                    </span>
                                    Multiple case studies
                                </div>
                                <div className='flex gap-2 text-white items-center ' >
                                    <span className='text-pink-200 ' >
                                        <PinkTick />
                                    </span>
                                    Multiple industry projects
                                </div>
                                <div className='flex d-none d-sm-flex gap-2 text-white items-center ' >
                                    <span className='text-pink-200 ' >
                                        <PinkTick />
                                    </span>
                                    Multiple certifications to build your profile
                                </div>
                            </section>
                            <ul className='list-disc my-4 text-slate-200 almaz tracking-wider ' >
                                <li className=' my- ' >  <BannerCourseCard heading="Work Integration" content='Engage with industry projects and gain practical skills. 
                                    Our industry experts guide you to become job-ready from day one.' />
                                </li>
                                <li className=' my-2 ' > <BannerCourseCard heading='Project-Based Learning' content='Sharpen your skills through hands-on tasks. This 
                                    practical approach sets you up for success in your chosen field.' /></li>
                                <li className=' my-2 ' >  <BannerCourseCard heading='Networking Opportunities' content='Connect with like-minded peers and industry 
                                    professionals. Find job opportunities through our active and supportive community.' /> </li>

                            </ul>
                        </article>

                    </section>
                    <section className='col-lg-6  ' >
                        <img src={require('../../Assest/HomeBanner.png')} alt="Home_Banner"
                            className='  ' />
                    </section>

                </main>
            </div>
            {/* <HomeBanner /> */}
        </main>
    )
}

export default HomeBannerNew
