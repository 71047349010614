import React, { useContext, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import FunnelIcon from '../Icons/FunnelIcon';
import CourseCard from '../Component/CourseCard';
import RightArrow from '../Icons/RightArrow';
import ArrowDown from '../Icons/ArrowDown';
import SLAstore, { SlaData } from '../Context/SLAstore';
import { Helmet } from 'react-helmet';
import { domain } from '../App';
import NavBar from '../Component/NavBar';

const AllCourses = () => {
    let { allCourses: courses } = useContext(SlaData)
    // let [courses, setCourses] = useState()
    let [data, setdata] = useState(courses)

    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setcurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [showOffcanvas, setOffcanvas] = useState(false)
    const itemsPerPage = 12;
    useEffect(() => {
        if (data) {
            const endOffset = itemOffset + itemsPerPage;
            setcurrentItems(data.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(data.length / itemsPerPage));
        }
    }, [itemOffset, data])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
        window.scroll(0, 0)
    };



    let filterCourse = (value) => {
        if (value != '') {
            let filterData = [...courses].filter((obj) => obj.Category.name == value)
            setdata(filterData)
        }
        else {
            setdata(courses)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [data])
    useEffect(() => {
        if (courses)
            setdata(courses)
    }, [courses])
    return (
        <div>
            <> <Helmet>
                <title>Explore All Our Courses with Advance Training Modules and Programmes</title>
                <meta name="description" content="Find all our courses to acquire knowldges, skills and experiences to make a promising career through our certified expert professional trainers in the industry." />
                <link rel="canonical" href={`${domain}/allCourse`} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
                <h1 className='d-none'>all training programmes </h1>
                <h2 className='d-none'>best courses to learn</h2>
            </>
            <NavBar theme='dark' />
            {data && <main className='container mx-auto   ' >
                <section className='flex container my-3 col-xl-10 mx-auto mb-4 flex-wrap items-center justify-between'>
                    <p className='m-0 ' >Showing
                        {itemOffset + 1}-{itemOffset + currentItems.length} of {data && data.length} results</p>

                    <div className='flex ms-auto border-slate-400 border-1 shadow-md justify-between
                     p-2 gap-2 rounded items-center'>
                        <FunnelIcon />
                        <select name="" id="" onChange={(e) => filterCourse(e.target.value)}
                            className='outline-none w-32 rounded' >
                            <option value="">Filter by</option>
                            {
                                courses && [...new Set(courses.map((obj) => obj.Category.name))].map((x, index) => {
                                    console.log(x);
                                    return (
                                        <option value={x}> {x}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </section>
                <section className='flex flex-wrap gap-3 mx-auto justify-center sm:justify-between '>
                    {
                        currentItems && [...currentItems].map((x, index) => {
                            console.log("course", x);

                            return (
                                <CourseCard page='all' obj={x} key={index} />
                            )
                        })
                    }
                </section   >
            </main>}
            <ReactPaginate
                breakLabel="..."
                // nextLabel={<ArrowDown/>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                // previousLabel={null}
                containerClassName='pagination'
                pageLinkClassName='page-num'
                previousLinkClassName='page-num'
                nextLinkClassName='page-num'
                activeLinkClassName='active'
            />
        </div>
    )
}

export default AllCourses