import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { domain, hostname } from '../App'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TitleBanner from '../Component/TitleBanner'
import NavBar from '../Component/NavBar'

const EventPages = () => {
    let [allEvents, setEvents] = useState([
        '../Assest/event1.png',
        '../Assest/event2.png',
        '../Assest/event3.png',
        '../Assest/event4.png',
    ])
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(`${hostname}/event/EventsView/`).then((response) => {
            console.log(response.data);
            setEvents(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    let navigate = useNavigate()
    return (
        <div className='poppins '>
            <NavBar theme='dark' />
            <> <Helmet>
                <title>Look At Our Company Events Insghtful Discussions and Knowldge</title>
                <meta name="description" content="Get register to secure your spot for session that full of best speakers and networking opportunities on the latest trends and innovations in training 
                industry." />
                <link rel="canonical" href={`${domain}/events`} />
            </Helmet>
                <h1 className='d-none'>Best training events</h1>
                <h2 className='d-none'>Top insghtful discussions </h2>
            </>
            {/* <TitleBanner name={"Events"} /> */}
            <div className='relative  ' >
                <img src={require('../Assest/slaevents.webp')} alt="EventBanner"
                    className='max-h-[60vh] object-cover w-full object-top ' />
                <h4 className='absolute left-1/2 text-white text-5xl sm:text-9xl border-t-4 border-b-4  
 -translate-x-1/2 top-1/2 -translate-y-1/2 ' >Events </h4>
            </div>
            <main className='text-center min-h-[50vh] my-5 mx-auto gap-2 w-fit'>
                {/* <h5>Events </h5>
                <p>Immerse Yourself in Nature's Beauty
                    Explore Our Blooming Gallery
                </p> */}
                <main className='flex flex-wrap justify-around gap-4'>
                    {
                        allEvents.map((obj) => {
                            return (
                                <div onClick={() => navigate(`/events/${obj.slug}`)} className='relative shadow p-2 border-2 rounded-xl' >
                                    <p className='absolute p-1 rounded-tr-xl rounded-bl-xl right-2 text-xs bg-blue-500 text-white'> {obj.status} </p>
                                    <img className='w-[20rem] border-2 rounded-xl ' loading='lazy' src={obj.events_img} alt="Events" />
                                </div>
                            )
                        })
                    }

                </main>
            </main>
        </div>
    )
}

export default EventPages