import React from 'react'
import HomeBanner from '../Component/HomeComponent/HomeBanner'
import HowWeProvide from '../Component/HomeComponent/HowWeProvide'
import OurCourses from '../Component/HomeComponent/OurCourses'
import DigitalMarketing from '../Component/HomeComponent/DigitalMarketing'
import HowItStarts from '../Component/HomeComponent/HowItStarts'
import Testimonial from '../Component/HomeComponent/Testimonial'
import EventsNews from '../Component/HomeComponent/EventsNews'
import Blogs from '../Component/HomeComponent/Blogs'
import BecomeInstructor from '../Component/HomeComponent/BecomeInstructor'
import Footer from '../Component/Footer'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import HomeBannerNew from '../Component/Banner/HomeBannerNew'
import NavBar from '../Component/NavBar'
const HomePage = () => {
    return (
        <div className='overflow-hidden ' >
            <> <Helmet>
                <title>Welcome to SkillLearningAcademy The Best Training Company </title>
                <meta name="description" content="We invite you to know more about SkillLearningAcademy's journey to the success and innovation in training, upskilling and get mentoring support and 
        guidance. " />
                <link rel="canonical" href={`${domain}`} />
            </Helmet>
                <h1 className='d-none'>best training company</h1>
                <h2 className='d-none'>top training institute </h2>
            </>
            {/* <HomeBanner /> */}
        
            <HomeBannerNew />

            <HowWeProvide />
            <OurCourses />
            <DigitalMarketing />
            <HowItStarts />
            <Testimonial />
            <EventsNews />
            <Blogs />
            <BecomeInstructor />

            {/* <Footer/> */}
        </div>
    )
}

export default HomePage