import React, { useContext, useEffect, useState } from 'react'
import SubjectSlides from '../Component/Blog/SubjectSlides'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { domain, hostname } from '../App'
import RightSideBlog from '../Component/RightSideBlog'
import axios from 'axios'
import NavBar from '../Component/NavBar'

const CategoryPage = () => {
    let navigate = useNavigate()
    let { slug } = useParams()
    let [allblog, setBlog] = useState([])
    let [courses, setCourses] = useState([])
    let getDataBasedCategory = () => {
        if (slug)
            axios.get(`${hostname}/blog/datas/${slug}/`).then((response) => {
                console.log("cat", response.data);
                setBlog(response.data.Blog)
                setCourses(response.data.Courses)
            }).catch((error) => {
                console.log("cat", error);
            })
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        getDataBasedCategory()
    }, [slug])
    return (
        <main>
            <NavBar theme='dark' />
            <> <Helmet>
                <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
                <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
                <link rel="canonical" href={`${domain}/category/${slug}`} />
            </Helmet>
                <h1 className='d-none'>informative blog content </h1>
                <h2 className='d-none'>best blogging tips and tricks </h2>
            </>
            <h4 className='text-center my-3'>{slug && slug} </h4>
            <div className='container mx-auto my-3 row'>
                <section className='col-md-8 col-xl-9'>
                    <p className='fw-medium text-2xl flex items-center mt-3 gap-2 '> <span className='p-[3px] bg-blue-500 rounded-full h-[12px] w-[1px] '></span>
                        Blogs </p>
                    {allblog && <SubjectSlides name="blog" data={allblog} />}


                    {courses && <>
                        <p className='fw-medium text-2xl flex items-center mt-3 gap-2 '> <span className='p-[3px] bg-blue-500 rounded-full h-[12px] w-[1px] '></span>
                            Courses </p>
                        {courses.length > 1 && <SubjectSlides name="course" data={courses} />}
                        {courses.length < 2 && courses[0] &&
                            <div onClick={() => navigate(`/${courses[0].slug}`)}
                                className='w-[20rem] p-3 bg-white rounded cursor-pointer hover:scale-[1.01] transition 
                                duration-500 shadow-sm border-1 border-slate-200 mt-2 my-2'>
                                <img src={courses[0].img && courses[0].img} loading='lazy' className='rounded-xl h-[20rem] w-full sm:h-[10rem] lg:h-[15rem] object-cover ' alt="Image" />
                                <p className='text-lg fw-medium mt-3' >{courses[0].metaTitle && courses[0].metaTitle.slice(0, 40)}
                                    {courses[0].metaTitle && courses[0].metaTitle.length > 40 && "..."} </p>
                                <p className='text-slate-500 text-sm '>{courses[0].metaDes && courses[0].metaDes.slice(0, 100)}{courses[0].metaDes && courses[0].metaDes.length > 100 && "..."} </p>
                            </div>}
                    </>
                    }
                </section>
                <section className='col-md-4 col-xl-3 '>
                    <RightSideBlog />
                </section>


            </div>
        </main>
    )
}

export default CategoryPage