import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import { hostname } from '../../App'

const CourseEnquiryForm = (props) => {
    let { show, setshow, css } = props
    let [errorMessage, setErrormessage] = useState('')
    const [contactForm, setContactForm] = useState({
        email: '',
        message: '',
        first_name: '',
        last_name: '',
        phone: '',
        captcha: false
    })
    let [loading, setloading] = useState('')
    let handlechange = (e) => {
        let { name, value } = e.target
        if (name == 'phone' && value.length > 10)
            value = contactForm.phone
        setContactForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    let validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(contactForm.email) && contactForm.phone != '' && contactForm.first_name != '' && contactForm.message != '' && contactForm.captcha) {
            setErrormessage('')
            return true
        }
        else if (!emailRegex.test(contactForm.email) && contactForm.phone == '' && contactForm.first_name == '' && contactForm.message == '') {
            setErrormessage('*Enter all the required inputs')
        }
        else if (!emailRegex.test(contactForm.email)) {
            setErrormessage("Enter the proper mail and other inputs")
        }
        else {
            setErrormessage('*Enter all the required inputs')
        }
    }
    let postContact = () => {
        if (contactForm.captcha) {


            if (validateForm()) {
                setloading('contact')
                axios.post(`${hostname}/blog/ContactsInformation/`, contactForm).then((response) => {
                    console.log(response.data);
                    toast.success('Your Message has been sended, Our team will get touch in short time.')
                    setloading('')
                    setshow(false)
                    setContactForm({
                        email: '',
                        message: '',
                        first_name: '',
                        last_name: '',
                        phone: ''
                    })
                }).catch((error) => {
                    console.log(error);
                    setloading('')
                })
            }
        }
        else {
            setErrormessage('*Check the Captcha')

        }
    }

    return (
        <>
            <div className={` rounded border-2 border-opacity-35 py-3 ${css ? css : "bg-[#FFFFFF] backdrop-blur-sm bg-opacity-25 "}  `} >
                <img src={require('../../Assest/SLA_logo_Blue.png')} alt="Logo"
                    className='w-[100px] mx-auto ' />

                <input value={contactForm.first_name} onChange={handlechange} name='first_name'
                    type="text" placeholder='Name *' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex bg-[#ffffff] bg-opacity-80 ' />
                <input value={contactForm.email} onChange={handlechange} name='email'
                    type="email" placeholder='Email *' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex bg-[#ffffff] bg-opacity-80 ' />
                <input value={contactForm.phone} onChange={handlechange} name='phone'
                    type="number" placeholder='Phone number *' className='outline-none p-2 rounded border-2 w-10/12 my-4 mx-auto flex bg-[#ffffff] bg-opacity-80 ' />
                <textarea value={contactForm.message} onChange={handlechange} name='message'
                    type="text" placeholder='Message *' className='outline-none p-2 rounded border-2 w-10/12 my-2 mx-auto flex bg-[#ffffff] bg-opacity-80 ' />
                <div className='p-2 flex items-center justify-center'>
                    <ReCAPTCHA
                        className=' md:scale-[0.80] inputbg '
                        spellCheck={contactForm.captcha}
                        sitekey="6Ledg_QpAAAAAKgVH6WcItGafyMHUtoShRHFmMVc"
                        onChange={() => {
                            setContactForm((prev) => ({
                                ...prev,
                                captcha: !contactForm.captcha
                            }))
                        }}
                    />
                </div>
                <p className=' text-red-600 text-center h-[20px]' id='errorInstructor' >{errorMessage} </p>
                <button disabled={loading} onClick={postContact} className=' w-10/12 mx-auto p-2 flex text-center justify-center rounded bg-blue-600 text-white '>
                    {loading ? "Loading.." : "Submit"} </button>
            </div >
        </>
    )
}


export default CourseEnquiryForm