import React, { useEffect, useState } from 'react'
import RightSideBlog from '../Component/RightSideBlog'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import EventsNews from '../Component/HomeComponent/EventsNews'
import Blogs from '../Component/HomeComponent/Blogs'
import NavBar from '../Component/NavBar'

const InterviewSkills = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let [activeCircle, setActiveCircle] = useState(0)
    let data = [
        {
            heading: 'High School Graduates : ',
            para: `Begin your journey to career success with foundational interview training that prepares you for job applications and interviews.`
        },
        {
            heading: 'College Students :',
            para: `Enhance your academic experience with interview preparation tips that bridge the gap between education and employment, helping you stand out to future 
            employers.`
        },
        {
            heading: 'Aspiring Professionals :  ',
            para: `Develop your interview skills and gain practical insights into the job market, making you more competitive for your desired 
            roles.`
        },
        {
            heading: 'Career Changers :  ',
            para: `Equip yourself with strategies to handle interviews for new fields with confidence and ease, aiding a smooth transition into a new 
            career.`
        },
        {
            heading: 'Working Professionals :  ',
            para: `Refine your interview preparation techniques to advance in your current role or secure new opportunities in your 
            field.`
        }, {
            heading: 'Educators and Trainers :',
            para: `Integrate our interview training programs into your curriculum or professional development to provide students with valuable skills for the job 
            market.`
        }, {
            heading: 'Tech Enthusiasts  : ',
            para: `Gain insights into the latest interview skills tailored for the tech industry, improving your ability to present your expertise 
            effectively.`
        },
    ]
    let sixPoints = [
        {
            heading: 'Aptitude Preparation',
            short: '1: Aptitude',
            para: `We help you build your aptitude skills through practice exercises and guidance, 
            ensuring you're ready for tests and assessments.`
        },
        {
            heading: 'Resume Building',
            short: '2: Resume ',
            para: `Create an impressive resume with our expert guidance. From traditional resumes to video resumes, we ensure you present yourself confidently 
            and professionally.`
        },
        {
            heading: 'Mock Interviews',
            short: '3: Mock  ',
            para: `Practice real-life interview scenarios through mock sessions. Get valuable feedback and build the confidence to face interviews 
            effortlessly.`
        },
        {
            heading: 'Communication Skills',
            short: '4: Communication ',
            para: `Learn how to communicate clearly and confidently. We focus on teaching you how to answer questions effectively 
            and leave a great impression.`
        },
        {
            heading: 'Interview Prep',
            short: '5: Prep',
            para: `Prepare for interviews with grooming tips, etiquette, and effective strategies to answer questions confidently
             and make a positive impression.`
        },
        {
            heading: 'Job Support ',
            short: '6: Job support ',
            para: `Merida HR team will assist you in finding relevant job opportunities. We connect you with companies actively hiring, so you can step 
            into your career confidently.`
        },
    ]
    let whyData = [
        {
            heading: 'Effective Interview Preparation : ',
            para: `Get interview preparation tips to enhance your ability to handle various interview scenarios confidently and 
            successfully.`
        },
        {
            heading: 'Industry-Recognized Training  :',
            para: `Boost your resume with training that demonstrates your proficiency in interview skills, setting you apart from other 
            candidates. `
        },
        {
            heading: 'Mock Interviews :  ',
            para: `Showcase your abilities through mock interviews that enhance your overall preparedness. `
        },
        {
            heading: 'Practical Experience :  ',
            para: `Engage in realistic mock interviews and feedback sessions that prepare you to tackle actual interview challenges effectively. `
        },
        {
            heading: 'Flexible Learning : ',
            para: `Benefit from our Hybrid Classes that offer the convenience of online learning paired with the benefits of interactive in-person 
            sessions.`
        },
        {
            heading: 'Develop Soft Skills :  ',
            para: `Improve your interview techniques and develop essential soft skills like communication and problem-solving. `
        },
        {
            heading: 'Get Job-Ready : ',
            para: `Ensure you are ready for the job market with structured interview training that highlights your capabilities and readiness for various roles. `
        },
    ]
    return (
        <div>
            <NavBar theme='dark' />
            <> <Helmet>
                <title>Learn Corporate Training Program from Best Trainer </title>
                <meta name="description" content="Enroll the corporate training course to learn team's performance tailored programs in leadership, communication and teamwork to boost productivity and
         sucess. " />
                <link rel="canonical" href={`${domain}/corporate-training`} />
            </Helmet>
                <h1 className='d-none'>Corporate Training Programme</h1>
                <h2 className='d-none'>Best Corporate Training Course </h2>
            </>
            <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
            {/* Content */}
            <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
                <h6 style={{ zIndex: 0 }}
                    className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
                    Crack Interviews
                </h6>
                <div style={{ zIndex: 6 }} className='relative '>
                    <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
                        Skills to Impress Employees
                    </h4>
                    <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
                        Get Job Ready
                        <img src={require('../Assest/arrows.png')} alt="Arrow"
                            className='w-[50px]' />
                    </h6>
                </div>
            </section>
            <main className='container my-5 ' >
                <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
                    <CropCircle2 activeCircle={activeCircle} scroll='whyslascroll'
                        setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
                </div>
                <div id='whyslascroll' className=' my-10 rounded contentbg p-3 poppins' >
                    <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
                    <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>

                </div>
                <div className='rounded my-3 bg-white  p-2 p-sm-5 '>
                    <section>
                        <h5 className='text-violet-800 text-3xl fw-semibold ' >
                            Interview Skills </h5>
                        <p> Mastering Interview skills is essential for job seekers aiming to crack an interview. At SkillLearningAcademy, we offer Interview Training programs designed to refine your interview preparation
                            and ensure you excel in any job interview. Our curriculum includes practical training and actionable interview preparation tips to help you understand and apply effective strategies for successful interviews.
                        </p>
                        <p>We cover crucial aspects such as how to prepare for an interview and techniques to crack interviews effectively. You will engage in mock interviews and real-world scenarios, which will directly enhance your ability to perform confidently and competently.

                        </p>
                        <p>The benefits of our interview workshops that we provide go beyond basic training. They help you gain essential communication skills, problem-solving techniques, and strategies to improve your performance in any interview setting. Our Hybrid Classes offer the best of both orlds, blending the ease of online learning with the engagement of in-person
                            sessions. </p>
                        <p>Our approach includes practical elements like mock interviews and feedback sessions, allowing you to apply what you've learned in a real-world context and build a strong interview skill set that makes a strong impression on employers.
                        </p>
                    </section>
                    <div className=' '>
                        <h5 className='text-violet-800 fw-semibold text-3xl' >Who is it for? </h5>
                        {
                            [...data].map((x) => (
                                <div>
                                    <h6 className='text-violet-800'>{x.heading} </h6>
                                    <p>{x.para} </p>
                                </div>
                            ))
                        }
                    </div>
                    <div className=' '>
                        <h5 className='text-violet-800 fw-semibold text-3xl ' > Why choose our Interview Skills Program? </h5>
                        {
                            [...whyData].map((x) => (
                                <div>
                                    <h6 className='text-violet-800'>{x.heading} </h6>
                                    <p>{x.para} </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </main>
            <EventsNews />
            <Blogs />
        </div>
    )
}

export default InterviewSkills