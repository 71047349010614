import React, { useEffect, useState } from 'react'
import CropCircle2 from '../Component/CourseComponent/CropCircle2'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import Blogs from '../Component/HomeComponent/Blogs'
import EventsNews from '../Component/HomeComponent/EventsNews'
import NavBar from '../Component/NavBar'

const TraningComeRequirtment = () => {
  let [activeCircle, setActiveCircle] = useState(0)
  let data = [
    {
      heading: 'High School Graduates : ',
      para: `Start your career early by participating in Employee Training Programs that guide your future educational and professional paths.`
    },
    {
      heading: 'College Students :',
      para: `Enrich your academic journey with Training Workshops that offer practical skills and real-world applications, bridging the gap between classroom learning and professional 
      experience.`
    },
    {
      heading: ' Aspiring Professionals :  ',
      para: `Develop your expertise and credentials in your field with our Recruitment Strategies to stand out in the job market and achieve your career 
      ambitions.`
    },
    {
      heading: 'Career Changers :  ',
      para: `Explore new fields with our Corporate Training Solutions and gain certifications that facilitate a smooth transition into a new career path.`
    },
    {
      heading: 'Working Professionals :  ',
      para: `Upgrade your skills through Dual Certification and gain credentials that open doors to new opportunities and career advancement within your current field or
       beyond.`
    }, {
      heading: 'Educators and Trainers :',
      para: `Integrate our Training and Recruitment programs into your curriculum or professional development plans to provide students with valuable industry-recognized
       credentials.`
    }, {
      heading: 'Tech Enthusiasts  : ',
      para: `Dive into the latest technologies with our training solutions that validate your expertise and enhance your application of cutting-edge
       innovations.`
    },
  ]
  let sixPoints = [
    {
      heading: ' Personalized Training Plan',
      short: '1: Training',
      para: `We assess your skills and interests to create a training plan that aligns perfectly with your career 
      goals.`
    },
    {
      heading: 'Industry-Relevant Skills',
      short: '2: Skills ',
      para: `We provide training that aligns with industry standards, ensuring you gain the skills employers are actively 
      seeking.`
    },
    {
      heading: 'Hands-On Learning',
      short: '3: Learning ',
      para: `Learn by doing through our triangle model: theory, practical tasks, and feedback to ensure you master 
      new skills. `
    },
    {
      heading: 'Resume Building',
      short: '4: Resume',
      para: `We assist in creating professional resumes, including video resumes, that showcase your skills and strengths
       effectively.`
    },
    {
      heading: 'Interview Preparation',
      short: '5: Interview',
      para: `Receive expert coaching and take mock interviews to build your confidence, ensuring you’re fully prepared for any upcoming 
      interviews.`
    },
    {
      heading: 'Placement Assisstance ',
      short: '6: Placement',
      para: `We connect you with top companies, offering support to secure job opportunities that match your skills and 
      interests.`
    },
  ]
  let whyData = [
    {
      heading: 'Effective Recruitment Strategies : ',
      para: `Master the best practices for onboarding new employees to ensure their seamless and effective integration into your 
      organization.`
    },
    {
      heading: 'Industry-Recognized Certifications :',
      para: `Enhance your resume with credentials that highlight your skills and set you apart in the job market. Our certifications validate your proficiency in training and 
      recruitment. `
    },
    {
      heading: 'Practical Experience :  ',
      para: `Engage in practical projects and real-life scenarios tailored for hands-on learning. This practical approach prepares you to tackle industry challenges and apply what you've learned 
      effectively. `
    },
    {
      heading: 'Expand Your Professional Network :  ',
      para: `Connect with industry leaders and peers, expanding your professional network and opening doors to future career opportunities. `
    },
    {
      heading: 'Develop Both Technical and Soft Skills : ',
      para: `Build your expertise in Employee Training Programs while also refining essential soft skills like communication and problem-solving. This balanced skill set prepares you for success in
       any professional setting.`
    },
    {
      heading: 'Job-Ready Status : ',
      para: `Ensure you are prepared for the professional world with our structured programs. Our training equips you with the skills and certifications that demonstrate your capabilities and readiness for the job 
      market.`
    },
    {
      heading: 'Flexible Learning Experience : ',
      para: `Benefit from our Hybrid Classes that offer the flexibility of online learning combined with the interactive structure of in-person sessions. This format accommodates your schedule while delivering in-depth 
      knowledge.`
    },
    {
      heading: 'Capstone Projects : ',
      para: `Showcase your skills through projects that reflect real-world applications, enhancing your portfolio and job 
      readiness. `
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='min-h-[50vh] bgsec' >
      <NavBar theme='dark' />
      <>
        <Helmet>
          <title>Latest Tech News & Insights - Read Our Blog | TechTalk </title>
          <meta name="description" content="Get insightful content on all the courses and more expert tips, latest trends full of Knowledge, Inspiration and software training updates and informations." />
          <link rel="canonical" href={`${domain}/traning-come-recruitment`} />
        </Helmet>
        <h1 className='d-none'>informative blog content </h1>
        <h2 className='d-none'>best blogging tips and tricks </h2>
      </>
      <img src={require('../Assest/whyslaBanner.webp')} className='w-full ' alt="WhySla" />
      {/* Content */}
      <section className='relative w-full mt-10 my-3 sm:mt-0 pt-8 sm:pt-16 poppins'>
        <h6 style={{ zIndex: 0 }}
          className='text-5xl sm:text-7xl xl:text-8xl text-nowrap fw-bold absolute top-0 left-1/2 -translate-x-1/2 txtbgz'>
          Career Support
        </h6>
        <div style={{ zIndex: 6 }} className='relative '>
          <h4 className=' text-3xl sm:text-5xl text-center poppins fw-semibold text-blue-800'>
            Training That Leads to Jobs
          </h4>
          <h6 className='text-center flex gap-2 mx-auto w-fit poppins text-xl sm:text-2xl fw-semibold'>
            Get Hired
            <img src={require('../Assest/arrows.png')} alt="Arrow"
              className='w-[50px]' />
          </h6>
        </div>
      </section>
      <main className='container my-5 ' >
        <div className='col-lg-8 col-xl-8 mx-auto p-4 flex' >
          <CropCircle2 activeCircle={activeCircle} scroll='traningcome'
            setActiveCircle={setActiveCircle} whyChooseData={sixPoints} />
        </div>
        <div id='traningcome' className=' my-10 rounded contentbg p-3 poppins' >
          <h5 className='text-violet-800 ' > {sixPoints[activeCircle].heading} </h5>
          <p className='my-2 text-sm' > {sixPoints[activeCircle].para}  </p>
        </div>
        <div className='rounded my-3 bg-white  p-2 p-sm-5 '>
          <section>
            <h5 className='text-violet-800 text-3xl fw-semibold ' >
              Training and Recruitment  </h5>
            <p>Effective employee development and hiring practices are crucial for organizational success. At SkillLearningAcademy we address both Employee Training and Recruitment needs with practical, industry-focused strategies. By providing targeted Employee Training Programs and refining Recruitment Strategies, we equip you with the essential tools and knowledge to enhance your workforce and hiring processes. Our programs are designed to meet industry standards and support your specific goals in both training and
              recruitment. </p>
            <p>Our programs cover a range of topics, including Corporate Training Solutions that focus on effective employee development and best practices for onboarding new employees. You’ll engage in real-world scenarios and learn strategies that directly impact your hiring and training processes.

            </p>
            <p>The benefits of our Training Workshops extend beyond theoretical knowledge. They equip you with the skills to improve communication, problem-solving, and overall effectiveness in a professional environment. For those seeking flexibility, our Hybrid Classes combine the convenience of online learning with the interactive aspects of in-person sessions.
            </p>
            <p>Our approach includes practical experiences such as Internship opportunities and Capstone Projects, allowing you to apply what you've learned in a real-world context and build a robust portfolio that stands out to potential employers.
            </p>
          </section>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl' >Who is it for? </h5>
            {
              [...data].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
          <div className=' '>
            <h5 className='text-violet-800 fw-semibold text-3xl ' > Why choose our Training and Recruitment program? </h5>
            {
              [...whyData].map((x) => (
                <div>
                  <h6 className='text-violet-800'>{x.heading} </h6>
                  <p>{x.para} </p>
                </div>
              ))
            }
          </div>
        </div>
      </main>
      <EventsNews />
      <Blogs />
    </div>
  )
}

export default TraningComeRequirtment