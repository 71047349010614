import React, { useEffect } from 'react'
import RightSideBlog from '../Component/RightSideBlog'
import { Helmet } from 'react-helmet'
import { domain } from '../App'
import NavBar from '../Component/NavBar'

const IndustrialVisit = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let who = [
        {
            heading: 'High School Graduates',
            para: 'Get a taste of different industries, helping you make informed decisions about your higher education or career choices.'
        },
        {
            heading: 'College Students ',
            para: 'Ideal for students eager to apply classroom knowledge in real-world situations. Experience how industries function up close, connecting theoretical knowledge with real-world application.'
        },
        {
            heading: 'Aspiring Entrepreneurs ',
            para: `Learn the ins and outs of different industries, from production processes to business strategies, providing you with a solid foundation for your entrepreneurial
             ambitions.`
        },
        {
            heading: 'Engineering Students ',
            para: 'Understand how your technical skills are applied in real-world scenarios, from manufacturing to software development, and how these industries function.'
        },
        {
            heading: 'Management Students  ',
            para: 'Get a look at how companies are managed, from supply chain operations to decision-making processes, enhancing your understanding of business dynamics.'
        },
        {
            heading: 'Educators and Faculty  ',
            para: `Provide your students with practical industry exposure, making learning more engaging and relevant by connecting theory with practice.`
        },
        {
            heading: 'Career Switchers',
            para: `Great for individuals considering a career change. Explore new industries and gain insights into different business environments, helping you make informed decisions about your next career
             move.`
        },
        {
            heading: 'Tech Enthusiasts',
            para: `For those passionate about technology and innovation, witness cutting-edge tech in action, from data centers to software development, and see how these innovations are applied in various 
            industries.`
        },

    ]
    let why = [
        {
            heading: 'Real-World Exposure',
            para: `Get firsthand experience of how industries operate, from manufacturing processes to business strategies, offering insights that textbooks
             provide.`
        },
        {
            heading: 'Industry Expertise ',
            para: 'Learn directly from industry professionals who share their knowledge, best practices, and insider tips, giving you an edge in your field.'
        },
        {
            heading: 'Interactive Learning  ',
            para: `Engage in interactive sessions and live demonstrations that bring theoretical concepts to life, making learning more impactful and enjoyable.`
        },
        {
            heading: 'Networking Opportunities  ',
            para: 'Connect with professionals and peers during visits, expanding your network and opening doors to future career opportunities.'
        },
        {
            heading: 'Inspiration and Motivation  ',
            para: 'Witnessing successful operations and innovative practices can inspire you to pursue your career goals'
        },

    ]
    return (
        <div>
            <NavBar theme='dark' />
            <>
                <Helmet>
                    <title>Showcase Your Real-Time Skill and Experience in Our Office.</title>
                    <meta name="description" content="We allow the students our office to gain practical insights, observe operations firsthand and interact with expert professionals provide learning opportunities and 
                experience. " />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <link rel="canonical" href={`${domain}/industrial-visit`} />
                </Helmet>
                <h1 className='d-none'>student visit the in house office </h1>
                <h2 className='d-none'>real experience in office work </h2>
            </>
            <main className='row my-4 container mx-auto'>
                <section className='col-lg-8 col-xl-9 '>
                    <div className='text-lg '>
                        <img src={require('../Assest/IndustrialVisit.webp')} loading='lazy' className=' w-full rounded object-cover '
                            alt="Corporate training" />
                        <h3 className=' my-4 fw-semibold '>Industrial Visit  </h3>
                        <p>From learning to real-world experience, we provide Industrial Visit programs to students and anyone who wants to learn with a hands-on understanding of how businesses operate. This program is an opportunity to witness the inner workings of a company, from the production line to the boardroom. Whether it's getting a glimpse into software development, or understanding the inner workings of a data center you’ll gain real-world insights that go far beyond the classroom.
                        </p>
                        <p>
                            Our College Industrial Visit Programs are designed to broaden your knowledge and give you a clear picture of the processes that drive successful businesses. You'll see how products are created, packaged, and distributed, gaining a deeper understanding of what it takes to run a business. These visits are more than just educational; they are memorable experiences that can inspire the entrepreneurial spirit within
                            you.
                        </p>
                        <p>
                            For students in Bangalore, our Industrial Visit in Bangalore option offers a unique chance to explore local industries, making learning both enjoyable and convenient. Discover how your classroom learning applies in real-world scenarios by joining us on this engaging
                            journey!
                        </p>
                        <p>
                            This is designed to be engaging, informative, and accessible to everyone, regardless of their prior knowledge of industrial visits. Expect an experience that is not only enriching but also truly
                            memorable.
                        </p>
                        <h6 className=' my-4 text-2xl fw-semibold '>
                            Who is it for?</h6>
                        {
                            who.map((obj, index) => (
                                <div>
                                    <h6 className='text-xl  ' > {obj.heading} </h6>
                                    <p> {obj.para} </p>
                                </div>
                            ))
                        }
                        <h6 className=' my-4 text-2xl fw-semibold '>
                            Why choose our Industrial Visit program?
                        </h6>
                        {
                            why.map((obj, index) => (
                                <div>
                                    <h6 className='text-xl  ' > {obj.heading} </h6>
                                    <p> {obj.para} </p>
                                </div>
                            ))
                        }

                    </div>
                </section>
                <section className='col-lg-4 col-xl-3 '>
                    <RightSideBlog />
                </section>
            </main>
        </div>
    )
}

export default IndustrialVisit